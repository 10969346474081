import React, {Component} from "react";
import {Router, Switch, Route} from "react-router-dom";
import {history} from '../../helpers';
import {Contact, Home, NotFound, Works} from "../pages";
import {Poems, Quotes, Blog} from "../redirects";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../assets/css/app.min.css";

class App extends Component {
    constructor(props) {
        super(props);

        history.listen((location, action) => {
            // clear alert on location change
        });
    }

    render() {
        // Set document title
        document.title = process.env.REACT_APP_TITLE;
        document.body.classList.add('dark');

        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route exact path="/contact" component={Contact}/>
                    <Route path="/works" component={Works}/>
                    <Route exact path="/blog" component={Blog}/>
                    <Route path="/poetry" component={Poems}/>
                    <Route path="/poems" component={Poems}/>
                    <Route path="/quotes" component={Quotes}/>
                    <Route path="*" component={NotFound}/>
                </Switch>
            </Router>
        );
    }
}

export default App;
