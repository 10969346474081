import{Component} from "react";

export class Quotes extends Component {

    render() {
        window.location.href = process.env.REACT_APP_QUOTES_URL
        document.title = "Quotes - " + process.env.REACT_APP_TITLE;
        return null;
    }
}
