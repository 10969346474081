import {Component} from "react";

export class Blog extends Component {

    render() {
        window.location.href = process.env.REACT_APP_BLOG_URL
        document.title = "Blog - " + process.env.REACT_APP_TITLE;
        return null;
    }
}
