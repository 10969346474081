import React, {Component} from "react";
import PropTypes from "prop-types";

class WorkItem extends Component {
    render() {
        let stringEndDate, stringStartDate, date;
        const {name, description, url, startDate, endDate} = this.props.work;
        if(endDate === null) {
            stringEndDate = "Current";
        } else {
            date = new Date(endDate.date);
            stringEndDate = date.getMonth() + "/" + date.getFullYear();
        }
        date = new Date(startDate.date);
        stringStartDate = date.getMonth() + "/" + date.getFullYear();

        return (
            <div className="work col-lg-10 mb-3">
                <div className="title">
                    <a target="_blank" rel="noreferrer noopener"
                       href={url}>
                        {name}
                    </a>
                </div>
                <div className="time">{stringStartDate} to {stringEndDate}</div>
                <div className="description">
                    {description}
                </div>
                <div className="website">
                    <a target="_blank" rel="noreferrer noopener"
                       href={url}>
                        Website
                    </a>
                </div>
            </div>
        );
    }
}

// PropTypes
WorkItem.propTypes = {
    work: PropTypes.object.isRequired
};

export {WorkItem};
