import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Navigation} from "../pages";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelopeOpenText} from '@fortawesome/free-solid-svg-icons'
import {faGithub, faTwitter, faLinkedin} from '@fortawesome/free-brands-svg-icons'

export class Home extends Component {
    state = {
        isLoading: false,
        news: []
    };

    componentDidMount() {
        // Axios.get(process.env.REACT_APP_API_URL + "/news").then(
        //     res => {
        //         this.setState({
        //             news: res.data._embedded.news
        //         })
        //     }
        // );
    }

    render() {
        return (
            <div className="d-flex flex-column h-100">
                <main className="content container flex-grow-1 mt-2 mt-md-4 mt-lg-5">
                    <div className="row">
                        <div className="col-12 d-flex">
                            <Navigation current="Home"/>
                        </div>
                    </div>
                    <div className="row mt-1 mt-md-3 mt-lg-4">
                        <div className="col-sm-4 col-md-3">
                            <img className="img-fluid"
                                 alt="Kenneth Batanyita"
                                 src="https://en.gravatar.com/avatar/e38b4f0530aeb52ad02af7f4f5da0841.jpg?size=1024"/>
                        </div>
                        <div className="col-sm-8 col-md-9 mt-1 mt-sm-2">
                            <div className="about">
                                I'm <span>Ken</span>neth <span>Kataiwa</span> Batanyita, <br/>
                                an <Link to="works">entrepreneur</Link><br/>
                                a <Link to="works">software developer</Link> based in Dar es Salaam,<br/>
                                and a <a href="http://poems.kenkataiwa.com">poet</a><br/>
                            </div>
                            <div className="profile-links mt-2">
                                <a target="_blank" rel="noopener noreferrer"
                                   className="mr-2 github"
                                   href="https://github.com/kenkataiwa">
                                    <FontAwesomeIcon icon={faGithub}/>
                                </a>
                                <a target="_blank" rel="noopener noreferrer"
                                   className="mr-2 linkedin"
                                   href="https://linkedin.com/in/kenkataiwa">
                                    <FontAwesomeIcon icon={faLinkedin}/>
                                </a>
                                <a target="_blank" rel="noopener noreferrer"
                                   className="mr-2 twitter"
                                   href="https://twitter.com/kenkataiwa">
                                    <FontAwesomeIcon icon={faTwitter}/>
                                </a>
                                <a href="mailto:mail@kenkataiwa.com"
                                   className="mr-2 white">
                                    <FontAwesomeIcon icon={faEnvelopeOpenText}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
