import React, {Component} from "react";

export class NotFound extends Component {
    render() {
        // Set document title
        document.title = "404 - " + process.env.REACT_APP_TITLE;

        return (
            <div className="d-flex flex-column h-100">
                <main className="content container flex-grow-1">
                    <div className="d-flex h-100 justify-content-center align-items-center">
                        <div className="not-found">
                            <p className="not-found-info text-center">
                                There is nothing to see here.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
