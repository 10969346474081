import * as Yup from 'yup'

export default (values) => Yup.object({
    name: Yup.string()
        .required('Please kindly let me know your name'),
    email: Yup.string()
        .email('Please provide a valid email address')
        .required('Please provide an email so I get back to you'),
    subject: Yup.string()
        .required('Please provide a subject'),
    message: Yup.string()
        .required('Please provide a message'),
})