import React, {Component} from "react";
import Axios from "axios";
import {Navigation} from "./Navigation";
import {WorkItem} from "../works";

export class Works extends Component {
    state = {
        isLoading: false,
        works: []
    };

    componentDidMount() {
        Axios.get(process.env.REACT_APP_API_URL + "/works").then(
            res => {
                this.setState({
                    works: res.data._embedded.works
                })
            }
        );
    }

    render() {
        // Set document title
        document.title = "Works - " + process.env.REACT_APP_TITLE;

        return (
            <div className="d-flex flex-column h-100">
                <main className="content container flex-grow-1 mt-2 mt-md-4 mt-lg-5">
                    <div className="row">
                        <div className="col-12 d-flex">
                            <Navigation current="Works"/>
                        </div>
                    </div>
                    <div className="works">
                        <div className="row">
                            {this.state.works.map(work => (
                                <WorkItem key={work.id} work={work}/>
                            ))}
                        </div>
                    </div>
                </main>
            </div>
        );
    }
}
