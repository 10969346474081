import React, {Component} from "react";
import {Navigation} from "./Navigation";
import {ContactForm} from "../contact";

class Contact extends Component {
    render() {
        // Set document title
        document.title = "Contact - " + process.env.REACT_APP_TITLE;

        return (
            <div className="d-flex flex-column h-100">
                <main className="content container flex-grow-1 mt-2 mt-md-4 mt-lg-5">
                    <div className="row">
                        <div className="col-12 d-flex">
                            <Navigation current="Contact me"/>
                        </div>
                    </div>

                    <ContactForm/>
                </main>
            </div>
        );
    }
}

export {Contact};
