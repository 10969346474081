import {Component} from "react";

export class Poems extends Component {

    render() {
        window.location.href = process.env.REACT_APP_POEMS_URL
        document.title = "Poems - " + process.env.REACT_APP_TITLE;
        return null;
    }
}
