import React from "react";
import {Link} from "react-router-dom";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Navigation(props) {
    let links = [
        {url: "/", label: "Home"},
        {url: "/works", label: "Works"},
        {url: "/blog", label: "Blog"},
        {url: "/contact", label: "Contact me"},
    ];
    return (
        <React.Fragment>
            <div className="dropdown d-flex align-items-center">
                <div className="dropdown-toggle no-caret mr-3 mb-2"
                        id="dropdownMenuButton"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <FontAwesomeIcon icon={faBars}/>
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {links.map(link => (
                        <Link className={"dropdown-item " + (link.label === props.current ? "active" : "")}
                              key={link.label}
                              to={"" + link.url}>
                            {link.label}
                        </Link>
                    ))}
                </div>
            </div>
            <h4 className="vertical-center">{props.current}</h4>
        </React.Fragment>
    );
}

export {Navigation};