import React, {Component} from "react";
import {ErrorMessage, Field, Form, Formik} from "formik";
import validate from "./ValidateContact";
import getValidationSchema from "./ValidationSchemaContact";
import Axios from "axios";
import qs from "qs";

class ContactForm extends Component {
    state = {
        contact : {},
        responseReceived: false,
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        }
    };

    handleSubmit = (values, {setSubmitting}) => {
        let contact = {
            name: values.name,
            email: values.email,
            subject: values.subject,
            message: values.message
        };
        let data = qs.stringify(contact);
        let url = process.env.REACT_APP_API_URL + "/contact";
        let method = 'post';
        Axios[method](url, data).then(
            res => {
                this.setState({
                    alertType: "alert-success",
                    responseReceived: true,
                    responseMessage: "Message received, thank you. I will get back to you ASAP.",
                    contact: res.data
                });
                setSubmitting(false);
            },
            (error) => {
                this.setState({
                    alertType: "alert-danger",
                    responseReceived: true,
                    responseMessage: "Message cannot be sent at this time, please try again later.",
                });
                setSubmitting(false);
            }
        );
    };

    checkFieldError = function (formProps, name) {
        return formProps.errors[name] && formProps.touched[name] ? "is-invalid" : "";
    };

    showResponse() {
        if (this.state.responseReceived === true)
            return (
                <div className={this.state.alertType + " alert alert-dismissible fade show"}
                     role="alert">
                    {this.state.responseMessage}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            );
        return <div/>
    }

    ItemForm = (formProps) => {
        const {
            isSubmitting,
        } = formProps;
        return (
            <div className="row">
                <Form className="col-md-8 col-lg-6">
                    {this.showResponse()}
                    <div className="form-group">
                        <label htmlFor="name">Your name</label>
                        <Field id="name" name="name"
                               className={"form-control " + this.checkFieldError(formProps, 'name')}
                               placeholder=""/>
                        <div className="invalid-feedback">
                            <ErrorMessage name="name"/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="email">Your email address</label>
                        <Field id="email" name="email"
                               className={"form-control " + this.checkFieldError(formProps, 'email')}
                               placeholder=""/>
                        <div className="invalid-feedback">
                            <ErrorMessage name="email"/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="subject">Subject</label>
                        <Field id="subject" name="subject"
                               className={"form-control " + this.checkFieldError(formProps, 'subject')}
                               placeholder=""/>
                        <div className="invalid-feedback">
                            <ErrorMessage name="subject"/>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <Field id="message" name="message"
                               component="textarea"
                               className={"form-control " + this.checkFieldError(formProps, 'message')}
                               placeholder=""/>
                        <div className="invalid-feedback">
                            <ErrorMessage name="message"/>
                        </div>
                    </div>

                    <div className="col-lg-6 ">
                        <div className="row">
                            <button
                                type="submit" className="btn btn-primary btn-block"
                                disabled={isSubmitting}>
                                {isSubmitting ? 'Sending' : 'Send'}
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        )
    };

    render() {
        return (
            <Formik
                enableReinitialize
                initialValues={this.state.initialValues}
                validate={validate(getValidationSchema)}
                onSubmit={this.handleSubmit}
                render={this.ItemForm}
            />);
    }
}

export {ContactForm};
